import React from 'react';
import Container from 'container';
import Headline from 'headline';
import Img from 'img';
import { navigate } from 'gatsby';
import s from './404.module.scss';

export default function Four04() {
  const title = ['Oops!', 'Looks like my', 'dog ate this page!'];
  return (
    <Container className={s.root}>
      <div className={s.wrapper}>
        <Img className={s.img} src="404" png />
        <div className={s.flex}>
          <Headline className={`${s.title} ${s.normal}`} h2 html="ERROR 404" />
          <div className={s.titleContainer}>
            {title.map((line, i) => (
              <Headline
                tertiary
                className={`${s.title} ${s.wild} ${i === 0 && s.line1} ${i
                  === 1 && s.line2} ${i === 2 && s.line3}`}
                h1
                wild
                html={line}
              />
            ))}
          </div>
        </div>
        <div className={s.textWrapper}>
          <p className={s.text}>
            Sorry, we couldn’t find the page you were looking for.
            <br className={s.lineBreak} />
            {' '}
             Please
            {' '}
            <span className={s.highlight}>contact us</span>
            {' '}
if you were looking
            for something important.
          </p>
          <p className={`${s.text}`}>
            Or you can click here to
            {' '}
            <span
              className={`${s.highlight} ${s.goBack}`}
              onClick={() => window.history.back()}
            >
              go back to where you were.
            </span>
          </p>
        </div>
      </div>
    </Container>
  );
}
